import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { http } from "../constant";
import { useNavigate } from "react-router";

export default function Employee() {
  const staffRef = useRef();
  const history = useNavigate();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [location, setLocation] = useState("");

  useEffect(() => {
    const check = localStorage.getItem("att-id");
    if (check) {
      history("/dash");
    }
  }, []);

  const clockIn = async (evt) => {
    evt.preventDefault();
    if (staffRef.current.value === "" || location === "") {
      return NotificationManager.error(
        "Please enter your Staff ID or Location",
        "Error"
      );
    }
    setLoader(true);
    try {
      const cred = {
        staff_id: staffRef.current.value,
      };
      const cred1 = {
        date: new Date(),
        attended: "pending",
        time: "12:00",
        location: location,
        staff_id: staffRef.current.value,
        clock_out: null,
      };

      console.log(cred1);
      const res = await axios.post(`${http}/api/v1/user/login`, cred);
      console.log(res.data.data);
      localStorage.setItem("att-name", res.data.data.first_name);
      localStorage.setItem("att-id", res.data.data.staff_id);
      if (res.data.code === 200) {
        const res = await axios.post(`${http}/api/v1/attendance`, cred1);
        if (res.data.code === 201) {
          console.log(res.data.data);
          setLoader(false);
          localStorage.setItem("attid", res.data.data.id);
          NotificationManager.success(
            "User clocked In Successfullly",
            "Success"
          );
          history("/dash");
        }
      }
      return res.data;
    } catch (err) {
      setLoader(false);
      console.log(err.response.data);
      if (err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError("An error occurred");
      }
      console.log(err.response);
      return err;
    }
  };

  return (
    <div>
      <main className="employee">
        <div className="employee-box1">
          <div className="">
            <img
              className="logo"
              style={{ paddingTop: "30px" }}
              src="./images/logo.svg"
              alt="logo"
            />
          </div>
          <div className="employee-head">Track Employees Attendance</div>
          <div className="">
            <img
              src="./images/Illustration.svg"
              className="img1"
              alt="illustration"
            />
          </div>
        </div>
        <div className="employee-box2">
          <div className="">
            <div className="employee-input">
              <div className="label-input">
                Employee ID <span style={{ color: "red" }}>*</span>
              </div>
              <div>
                <input
                  className="input-emp"
                  placeholder="Enter your employee ID"
                  ref={staffRef}
                />
              </div>
            </div>

            <div className="radio-group">
              <div className="working">Where are you working from today ?</div>

              <label class="container">
                Office
                <input
                  type="radio"
                  name="location"
                  value="Office"
                  onChange={() => setLocation("Office")}
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                On Site
                <input
                  type="radio"
                  name="location"
                  value="On Site"
                  onChange={() => setLocation("On Site")}
                />
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Remote
                <input
                  type="radio"
                  name="location"
                  value="Remote"
                  onChange={() => setLocation("Remote")}
                />
                <span class="checkmark"></span>
              </label>

              <div style={{ marginTop: "30px", color: "red" }}>
                {error.length > 0 ? error : ""}
              </div>
            </div>
            <div
              style={{
                marginLeft: "150px",
                marginRight: "auto",
                width: "20px",
              }}
              className={loader === true ? "loader" : "none"}
            ></div>

            <button onClick={clockIn} className="button-att">
              Mark Attendance
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
