import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { http } from "../constant";
import { useNavigate } from "react-router";
import Bar from "../components/Bar";

export default function Approval() {
  const history = useNavigate();
  const [data, setData] = useState([]);
  const [inData, setInData] = useState([]);
  const [data1, setData1] = useState([]);
  const [outData, setOutData] = useState([]);
  const [greet, setGreet] = useState("");
  const [navState, setNavState] = useState(false);
  const [loader, setLoader] = useState(false);
  const name = localStorage.getItem("admin_name");
  const getData = async () => {
    try {
      setLoader(true);
      let token = localStorage.getItem("att_token");
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      const res = await axios.get(`${http}/api/v1/attendance/all`);
      console.log(res.data.data);
      if (res.data.code === 200) {
        setLoader(false);
      }
      let newData = res.data.data.attendance.reverse();
      let allUsers = res.data.data.allUsers.length;
      let inp = newData.filter(
        (item) => item.attended === "true" && item.clock_out === null
      );

      let out = Number(allUsers) - Number(inp.length);
      setInData(inp);
      setOutData(out);
      setData(res.data.data.attendance.reverse());
      setData1(res.data.data.attendance.reverse());
      return res.data;
    } catch (err) {
      console.log(err.response.data);
      setLoader(false);
      return err;
    }
  };

  const approve = async (id, status) => {
    const cred = { staff_id: id, status };
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.patch(`${http}/api/v1/attendance`, cred);
    if (res.data.code === 200) {
      getData();
      localStorage.setItem("attendanceId", res.data.data.staff_id);
      if (status === "true") {
        NotificationManager.success("Approved", "Success");
      }
      if (status === "false") {
        NotificationManager.success("Rejected", "Success");
      }
    }
  };

  const checkUser = async () => {
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/user/profile`, {
      name: localStorage.getItem("admin_name"),
    });
    const role = res.data.data.role;
    if (role.toLowerCase() === "user") {
      localStorage.removeItem("admin_name");
      history("/");
    }
  };

  const search = (evt) => {
    let val = evt.target.value;
    if (val.length > 0) {
      const ser = data1.filter(
        (item) =>
          item.user.first_name.toLowerCase().includes(val.toLowerCase()) ||
          item.user.last_name.toLowerCase().includes(val.toLowerCase())
      );
      setData(ser);
    } else {
      setData(data1);
    }
  };

  const greetings = () => {
    var d = new Date();
    var time = d.getHours();

    if (time < 12) {
      setGreet("Good Morning");
    }
    if (time >= 12) {
      setGreet("Good Afternoon");
    }
    if (time >= 16) {
      setGreet("Good Evening");
    }
  };

  const changeNav = () => {
    setNavState(!navState);
  };

  useEffect(() => {
    checkUser();
    getData();
    greetings();
    if (!localStorage.getItem("admin_name")) {
      window.location.href = "/";
    }
  }, []);
  return (
    <div>
      <main className="main">
        <Header search={search} changeNav={changeNav} />
        {navState === true ? <Bar /> : null}

        <div className="section1">
          <div className="sign-head">
            {greet}, {name}
          </div>

          <div className="admin-button">
            <button className="new-clock">
              <div className="clock-text">{inData.length}</div>
              <div className="clock-text2">In</div>
            </button>
            <button className="new-clock1">
              <div className="clock-text">{outData}</div>
              <div className="clock-text2">out</div>
            </button>
          </div>
        </div>

        <div className="sign-dash">
          <h3 className="act">Attendance</h3>

          <table>
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Clock-In</th>
              <th>Clock-Out</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
            {data.map((item) => {
              return (
                <tr>
                  <td>{item.staff_id}</td>
                  <td>
                    {item.user.first_name} {item.user.last_name}
                  </td>
                  <td>{new Date(item.date).toLocaleTimeString()}</td>
                  <td>
                    {item["clock_out"] === null
                      ? "Not clocked out"
                      : new Date(item["clock_out"]).toLocaleTimeString()}
                  </td>
                  <td>{new Date(item.date).toDateString()}</td>
                  <td>{item.location}</td>
                  <td
                    onClick={() => {
                      approve(item.id, "false");
                    }}
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    {item.attended === "false" ? "Rejected" : "Reject"}
                  </td>
                  <td
                    onClick={() => {
                      approve(item.id, "true");
                    }}
                    style={{ color: "green", cursor: "pointer" }}
                  >
                    {item.attended === "true" ? "Approved" : "Approve"}
                  </td>
                </tr>
              );
            })}
          </table>
          {
            <div
              style={{ width: "20px" }}
              className={loader === true ? "loader" : "none"}
            ></div>
          }
        </div>
      </main>
    </div>
  );
}
