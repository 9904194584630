import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { http } from "../constant";
import { useNavigate } from "react-router";
import Bar from "../components/Bar";

export default function BakerInterest() {
  const history = useNavigate();
  const [navState, setNavState] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const res = await axios.get(`https://bakerhomes.io/api/v1/home`);

      if (res.data.code === 200) {
        console.log(res.data.data);
        setData(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const search = (evt) => {};

  const logout = () => {
    localStorage.removeItem("admin_name");
    localStorage.removeItem("att_token");
    history("/");
  };

  const checkUser = async () => {
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/user/profile`, {
      name: localStorage.getItem("admin_name"),
    });
    const role = res.data.data.role;
    if (role.toLowerCase() === "user") {
      localStorage.removeItem("admin_name");
      history("/");
    }
  };

  const changeNav = () => {
    setNavState(!navState);
  };

  useEffect(() => {
    checkUser();
    getData();
    if (!localStorage.getItem("admin_name")) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div>
      {" "}
      <main className="main">
        <Header search={search} logout={logout} changeNav={changeNav} />

        {navState === true ? <Bar /> : null}

        <section className="farm">
          <div className="req">Baker Homes Interests (6)</div>

          <div className="cardList">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => history("/interest/1")}
            >
              <div className="ctext1">Request #001</div>
              <div className="ctext2">
                I would like to make inquiries regarding one of your properties
              </div>

              <div className="card-flex">
                <img src="./images/gr.svg" alt="gr" />

                <div>
                  <img src="./images/gr2.svg" alt="gr" />
                  <img src="./images/gr3.svg" alt="gr" />
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => history("/interest/1")}
            >
              <div className="ctext1">Request #002</div>
              <div className="ctext2">
                I would like to make inquiries regarding one of your properties
              </div>

              <div className="card-flex">
                <img src="./images/gr.svg" alt="gr" />

                <div>
                  <img src="./images/gr2.svg" alt="gr" />
                  <img src="./images/gr3.svg" alt="gr" />
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => history("/interest/3")}
            >
              <div className="ctext1">Request #003</div>
              <div className="ctext2">
                I would like to make inquiries regarding one of your properties
              </div>

              <div className="card-flex">
                <img src="./images/gr.svg" alt="gr" />

                <div>
                  <img src="./images/gr2.svg" alt="gr" />
                  <img src="./images/gr3.svg" alt="gr" />
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => history("/interest/4")}
            >
              <div className="ctext1">Request #004</div>
              <div className="ctext2">
                I would like to make inquiries regarding one of your properties
              </div>

              <div className="card-flex">
                <img src="./images/gr.svg" alt="gr" />

                <div>
                  <img src="./images/gr2.svg" alt="gr" />
                  <img src="./images/gr3.svg" alt="gr" />
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => history("/interest/5")}
            >
              <div className="ctext1">Request #005</div>
              <div className="ctext2">
                I would like to make inquiries regarding one of your properties
              </div>

              <div className="card-flex">
                <img src="./images/gr.svg" alt="gr" />

                <div>
                  <img src="./images/gr2.svg" alt="gr" />
                  <img src="./images/gr3.svg" alt="gr" />
                </div>
              </div>
            </div>

            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => history("/interest/6")}
            >
              <div className="ctext1">Request #006</div>
              <div className="ctext2">
                I would like to make inquiries regarding one of your properties
              </div>

              <div className="card-flex">
                <img src="./images/gr.svg" alt="gr" />

                <div>
                  <img src="./images/gr2.svg" alt="gr" />
                  <img src="./images/gr3.svg" alt="gr" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
