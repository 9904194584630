import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import axios from "axios";
import { http } from "../constant";
import { useNavigate } from "react-router";
import Bar from "../components/Bar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import writeXlsxFile from "write-excel-file";
import Moment from "moment";

export default function AdminAttendance() {
  const history = useNavigate();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [users, setUsers] = useState([]);
  const [navState, setNavState] = useState(false);
  const [inData, setInData] = useState([]);
  const [outData, setOutData] = useState([]);
  const [greet, setGreet] = useState("");
  const [loader, setLoader] = useState(false);
  const name = localStorage.getItem("admin_name");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const optionRef = useRef();

  const schema = [
    // Column #1
    {
      column: "Name",
      type: String,
      value: (item) => item.name,
    },
    {
      column: "Clock-In",
      type: String,
      value: (item) => item.clockin,
    },
    {
      column: "Clock-Out",
      type: String,
      value: (item) => item.clockout,
    },
    {
      column: "Location",
      type: String,
      value: (item) => item.location,
    },
    {
      column: "Status",
      type: String,
      value: (item) => item.status,
    },
  ];

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value}
    </button>
  );
  const getData = async () => {
    try {
      setLoader(true);
      let token = localStorage.getItem("att_token");
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      const res = await axios.get(`${http}/api/v1/attendance/all`);

      console.log(res);
      if (res.data.code === 200) {
        setLoader(false);
      }
      let newData = res.data.data.attendance.reverse();
      let allUsers = res.data.data.allUsers.length;
      setUsers(res.data.data.allUsers);
      let inp = newData.filter(
        (item) => item.attended === "true" && item.clock_out === null
      );

      let out = Number(allUsers) - Number(inp.length);
      setInData(inp);
      setOutData(out);

      setData(
        res.data.data.attendance.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        })
      );
      setData1(res.data.data.attendance.reverse());
      return res.data;
    } catch (err) {
      console.log(err);
      setLoader(false);
      return err;
    }
  };

  const getRange = async () => {
    try {
      setLoader(true);
      let token = localStorage.getItem("att_token");
      axios.defaults.headers.common["Authorization"] = "JWT " + token;

      const start = startDate || new Date();
      const end = endDate || new Date();

      const cred = {
        start: Moment(start).format("YYYY-MM-DD"),
        end: Moment(end).format("YYYY-MM-DD"),
      };

      console.log(cred);

      const res = await axios.post(`${http}/api/v1/attendance/range`, cred);
      if (res.data.code === 200) {
        setLoader(false);
      }

      const info = res.data.data.attendance.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });

      console.log(info);

      setData(info);
      setData1(info);
      return res.data;
    } catch (err) {
      console.log(err);
      setLoader(false);
      return err;
    }
  };

  const download = () => {
    let allData = [];
    console.log(data);
    data
      .sort((a, b) => Number(a.staff_id) - Number(b.staff_id))
      .map((item, index) => {
        (async () => {
          try {
            const obj = {
              name: item.user.first_name,
              clockin: item.createdAt,
              location: item.location,
              status: item.attended === "true" ? "approved" : "rejected",
            };

            allData.push(obj);

            if (data.length - 1 === index) {
              await writeXlsxFile(allData, {
                schema,
                fileName: "attendance.xlsx",
              });
            }
          } catch (err) {}
        })();
      });
  };

  const search = (evt) => {
    let val = evt.target.value;
    if (val.length > 0) {
      const ser = data1.filter(
        (item) =>
          item.user.first_name.toLowerCase().includes(val.toLowerCase()) ||
          item.user.last_name.toLowerCase().includes(val.toLowerCase())
      );
      setData(ser);
    } else {
      setData(data1);
    }
  };

  const greetings = () => {
    const d = new Date();
    const time = d.getHours();

    if (time < 12) {
      setGreet("Good Morning");
    }
    if (time >= 12) {
      setGreet("Good Afternoon");
    }
    if (time >= 16) {
      setGreet("Good Evening");
    }
  };

  const logout = () => {
    localStorage.removeItem("admin_name");
    localStorage.removeItem("att_token");
    history("/");
  };

  const checkUser = async () => {
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/user/profile`, {
      name: localStorage.getItem("admin_name"),
    });
    const role = res.data.data.role;
    if (role.toLowerCase() === "user") {
      localStorage.removeItem("admin_name");
      history("/");
    }
  };

  const changeNav = () => {
    setNavState(!navState);
  };

  const filterName = (name) => {
    console.log(data1);
    console.log(name);

    const ser = data1.filter(
      (item) =>
        item.user.first_name?.toLowerCase().includes(name?.toLowerCase()) ||
        item.user.last_name?.toLowerCase().includes(name?.toLowerCase())
    );
    setData(ser);
  };

  useEffect(() => {
    getRange();
    if (!localStorage.getItem("admin_name")) {
      window.location.href = "/";
    }
  }, [endDate]);

  useEffect(() => {
    checkUser();
    greetings();
    getData();
    if (!localStorage.getItem("admin_name")) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div>
      {" "}
      <main className="main">
        <Header search={search} logout={logout} changeNav={changeNav} />

        {navState === true ? <Bar /> : null}

        <div className="section1">
          <div className="sign-head">
            {greet}, {name}
          </div>

          <div className="admin-button">
            <button className="new-clock">
              <div className="clock-text">{inData.length}</div>
              <div className="clock-text2">In</div>
            </button>
            <button className="new-clock1">
              <div className="clock-text">{outData}</div>
              <div className="clock-text2">out</div>
            </button>
          </div>
        </div>

        <div className="sign-dash">
          <div className="sheet">
            <h3 className="act">Attendance Sheet</h3>

            <div className="sheet-inner">
              <div>
                <div className="inner-date">
                  <div> Start Date</div>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    customInput={<ExampleCustomInput />}
                  />
                </div>
              </div>
              <div>
                <div className="inner-date">
                  <div> End Date</div>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    customInput={<ExampleCustomInput />}
                  />
                </div>
              </div>

              <img src="./images/download.svg" alt="download" />
              <div onClick={download} style={{ cursor: "pointer" }}>
                Download
              </div>

              <div>
                <select
                  ref={optionRef}
                  onChange={() => filterName(optionRef.current.value)}
                >
                  <option>Filter by Name</option>
                  {users.map((item, index) => {
                    return (
                      <option key={index} value={item.first_name}>
                        {item.first_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <table>
            <tbody>
              <tr>
                <th>Employee ID</th>
                <th>Name</th>
                <th>Clock-In</th>
                <th>Clock-Out</th>
                <th>Date</th>
                <th>Location</th>
                <th>Status</th>
              </tr>

              {data.map((item) => {
                return (
                  <tr key={item.staff_id}>
                    <td>{item.staff_id}</td>
                    <td>
                      {item.user?.first_name} {item.user?.last_name}
                    </td>
                    <td>{new Date(item.date).toLocaleTimeString()}</td>
                    <td>
                      {item["clock_out"] === null
                        ? "Not clocked out"
                        : new Date(item["clock_out"]).toLocaleTimeString()}
                    </td>
                    <td>{new Date(item.createdAt).toDateString()}</td>
                    <td>{item.location}</td>
                    <td>
                      {item.attended === "true" ? (
                        <span style={{ color: "green" }}>Approved</span>
                      ) : item.attended === "false" ? (
                        <span style={{ color: "red" }}>Rejected</span>
                      ) : (
                        <span>Pending</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {
            <div
              style={{ width: "20px" }}
              className={loader === true ? "loader" : "none"}
            ></div>
          }
        </div>
      </main>
    </div>
  );
}
