import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { http } from "../constant";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Bar from "../components/Bar";

export default function Leave() {
  const history = useNavigate();
  const [data, setData] = useState({});
  const [data1, setData1] = useState([]);
  const [navState, setNavState] = useState(false);
  const [loader, setLoader] = useState(false);

  const startRef = useRef();
  const leaveRef = useRef();
  const endRef = useRef();
  const reasonRef = useRef();
  const durationRef = useRef();

  const getProfile = async () => {
    try {
      const cred = {
        id: localStorage.getItem("att-id"),
      };
      const res = await axios.post(`${http}/api/v1/user/profile2`, cred);

      console.log(res.data);
      if (res.data.code === 200) {
        setData(res.data.data);
      }
      return res.data;
    } catch (err) {
      return err;
    }
  };

  const createLeave = async () => {
    try {
      const cred = {
        staff_id: localStorage.getItem("att-id"),
        duration: "",
        leave_type: leaveRef.current.value,
        start_date: startRef.current.value,
        end_date: endRef.current.value,
        reason: reasonRef.current.value,
      };

      const res = await axios.post(`${http}/api/v1/leave`, cred);
      if (res.data.code === 201) {
        NotificationManager.success("Leave request has been sent", "Success");
      }

      setTimeout(() => {
        window.location.reload();
      }, 10000);
    } catch (err) {
      console.log(err);
      console.log(err.response.data.message);
      if (err.response.data.message) {
        NotificationManager.error(err.response.data.message);
      } else {
        NotificationManager.error("An errorr occured", "Error");
      }
    }
  };

  const getLeave = async () => {
    try {
      const cred = {
        id: localStorage.getItem("att-id"),
      };
      const res = await axios.post(`${http}/api/v1/leave/staff`, cred);
      setData1(res.data.data);
    } catch (err) {
      NotificationManager.error("An errorr occured", "Error");
    }
  };

  useEffect(() => {
    getProfile();
    getLeave();
  }, []);
  return (
    <div>
      <main className="main">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            onClick={() => history("/employee")}
            style={{ paddingTop: "30px" }}
            className="logo"
            src="./images/logo.svg"
            alt="logo"
          />

          <button
            style={{ cursor: "pointer" }}
            onClick={() => history("/leave")}
            className="lbut"
          >
            Leave
          </button>
        </div>

        {navState === true ? <Bar /> : null}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "200px",
          }}
        >
          <button style={{ cursor: "pointer" }} className="clock">
            {data?.days_used} days used
          </button>
          <button style={{ cursor: "pointer" }} className="clock">
            {data?.days_left} days left
          </button>
          <button style={{ cursor: "pointer" }} className="clock">
            {data?.annual_leave} days Annual Leave
          </button>
        </div>

        <div className="sign-dash">
          <h3 className="act">Leave History</h3>

          <table>
            <tr>
              <th>Employee ID</th>
              <th>Leave Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
            </tr>
            {data1.map((item) => {
              return (
                <tr>
                  <td>{item.staff_id}</td>
                  <td>{item.leave_type}</td>
                  <td>{new Date(item.start_date).toDateString()}</td>
                  <td>{new Date(item.end_date).toDateString()}</td>
                  <td>
                    {item.status === "true" ? (
                      <span style={{ color: "green" }}>Approved</span>
                    ) : item.status === "false" ? (
                      <span style={{ color: "red" }}>Rejected</span>
                    ) : (
                      <span>Pending</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <div className="setting_box2">
          <div className="setting_text11">Leave Application</div>
          <div className="setting_form_box">
            <div className="setting_label">Leave Type</div>
            <div>
              <select ref={leaveRef} className="setting_input">
                <option>Annual Leave</option>
                <option>Sick Leave</option>
                <option>Maternity Leave</option>
                <option>Leave of Absence</option>
                <option>unPaid Absence</option>
              </select>
            </div>
          </div>
          <div className="setting_form_box">
            <div className="setting_label">Start Date</div>
            <div>
              <input className="setting_input" ref={startRef} type="date" />
            </div>
          </div>
          <div className="setting_form_box">
            <div className="setting_label">End Date</div>
            <div>
              <input className="setting_input" ref={endRef} type="date" />
            </div>
          </div>
          <div className="setting_form_box">
            <div className="setting_label">Reason</div>
            <div>
              <input
                type="text"
                className="setting_input"
                placeholder="Enter your reason"
                ref={reasonRef}
              />
            </div>
          </div>
          <button
            style={{ cursor: "pointer" }}
            onClick={createLeave}
            className="setting_but"
          >
            Submit
          </button>
        </div>
      </main>
    </div>
  );
}
