import React, { useRef } from "react";
import { Link } from "react-router-dom";

export default function Header(props) {
  return (
    <div>
      <div className="admin-inner">
        <div className="admin-f">
          <Link to="/admindash">
            <img className="logo" src="./images/logo.svg" alt="logo" />
          </Link>

          <input
            className="admin-header-input"
            placeholder="Search for employee name, ID"
            onChange={props.search}
          />

          <div className="admin-profile-mobile">
            <img
              style={{ paddingTop: "15px" }}
              src="./images/notifications.svg"
              alt="notifications"
            />
            <img src="./images/profile.svg" alt="profile" />
            <img
              onClick={props.changeNav}
              className="admin-arrow"
              src="./images/arrow.svg"
              alt="arrow"
              style={{ width: "12px", paddingLeft: "10px", cursor: "pointer" }}
            />
          </div>
        </div>

        <div className="admin-section">
          <div className="admin-profile">
            <img
              style={{ paddingTop: "15px" }}
              src="./images/notifications.svg"
              alt="notifications"
            />
            <img src="./images/profile.svg" alt="profile" />
            <img
              onClick={props.changeNav}
              className="admin-arrow"
              src="./images/arrow.svg"
              alt="arrow"
              style={{ width: "12px", paddingLeft: "10px", cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
