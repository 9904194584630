import { Route, Routes, BrowserRouter } from "react-router-dom";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./App.css";
import "./mobile.css";
import "./new.css";
import Home from "./pages/Home";
import Approval from "./pages/Approval";
import Employee from "./pages/Employee";
import EmployeeDash from "./pages/EmployeeDash";
import AdminDash from "./pages/AdminDash";
import EmployeeList from "./pages/EmployeeList";
import AdminLogin from "./pages/AdminLogin";
import AdminAttendance from "./pages/AdminAttendance";
import Settings from "./pages/Settings";
import FarmContact from "./pages/FarmContact";
import FarmRequest from "./pages/FarmRequest";
import BakerContact from "./pages/BakerHomeContact";
import BakerSchedule from "./pages/BakerHomeSchedule";
import BakerInterest from "./pages/BakerInterest";
import FarmOrderDetail from "./pages/FarmOrderDetail";
import BakerHomeDetail from "./pages/BakerHomeDetail";
import BakerInterestDetail from "./pages/BakerInterestDetail";
import BilContact from "./pages/BilContact";
import Leave from "./pages/Leave";
import LeaveApproval from "./pages/LeaveApproval";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/employee" element={<Employee />} />
        <Route exact path="/dash" element={<EmployeeDash />} />
        <Route exact path="/admindash" element={<AdminDash />} />
        <Route exact path="/list" element={<EmployeeList />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/" element={<AdminLogin />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/leave" element={<Leave />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/approval" element={<Approval />} />
        <Route exact path="/leave-approval" element={<LeaveApproval />} />
        <Route exact path="/attendance" element={<AdminAttendance />} />
        <Route exact path="/contact" element={<FarmContact />} />
        <Route exact path="/bil" element={<BilContact />} />
        <Route exact path="/contact2" element={<BakerContact />} />
        <Route exact path="/schedule" element={<BakerSchedule />} />
        <Route exact path="/interest" element={<BakerInterest />} />
        <Route exact path="/request" element={<FarmRequest />} />
        <Route exact path="/request/:id" element={<FarmOrderDetail />} />
        <Route exact path="/schedule/:id" element={<BakerHomeDetail />} />
        <Route exact path="/interest/:id" element={<BakerInterestDetail />} />
      </Routes>
      <NotificationContainer />
    </BrowserRouter>
  );
}

export default App;
