import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

export default function Bar() {
  const history = useNavigate();
  const logout = () => {
    localStorage.removeItem("admin_name");
    localStorage.removeItem("att_token");
    history("/");
  };
  const role = localStorage.getItem("att_role");
  return (
    <div>
      <div className="nav-modal">
        <div className="inner-nav">
          <div className="nav-item">
            <img className="icon" src="./images/qr.svg" alt="qr" />
            <span>
              <Link style={{ color: "#1F1F1F" }} to="/admindash">
                Home
              </Link>
            </span>
          </div>
          <div className="nav-item">
            <img className="icon" src="./images/users.svg" alt="qr" />
            <span>
              <Link style={{ color: "#1F1F1F" }} to="/approval">
                Approval
              </Link>
            </span>
          </div>
          <div className="nav-item">
            <img className="icon" src="./images/users.svg" alt="qr" />
            <span>
              <Link style={{ color: "#1F1F1F" }} to="/leave-approval">
                Leave Approval
              </Link>
            </span>
          </div>
          {role === "super-admin" ? (
            <div className="nav-item">
              {" "}
              <img className="icon" src="./images/users.svg" alt="qr" />
              <span>
                <Link style={{ color: "#1F1F1F" }} to="/list">
                  {" "}
                  Employees
                </Link>
              </span>
            </div>
          ) : null}

          <div className="nav-item">
            {" "}
            <img className="icon" src="./images/note.svg" alt="qr" />
            <span>
              {" "}
              <Link style={{ color: "#1F1F1F" }} to="/attendance">
                Attendance Sheet
              </Link>
            </span>
          </div>
          <div className="nav-item">
            {" "}
            <img className="icon" src="./images/settings.svg" alt="qr" />
            <span>
              {" "}
              <Link style={{ color: "#1F1F1F" }} to="/settings">
                Settings
              </Link>
            </span>
          </div>
          <div className="nav-item">
            {" "}
            <img className="icon" src="./images/note.svg" alt="qr" />
            <span>
              {" "}
              <Link style={{ color: "#1F1F1F" }} to="/request">
                Farm Requests
              </Link>
            </span>
          </div>
          <div className="nav-item">
            {" "}
            <img className="icon" src="./images/note.svg" alt="qr" />
            <span>
              {" "}
              <Link style={{ color: "#1F1F1F" }} to="/bil">
                BIL Contact
              </Link>
            </span>
          </div>
          <div className="nav-item">
            {" "}
            <img className="icon" src="./images/note.svg" alt="qr" />
            <span>
              {" "}
              <Link style={{ color: "#1F1F1F" }} to="/contact">
                Farm Page Contact
              </Link>
            </span>
          </div>
          <div className="nav-item">
            {" "}
            <img className="icon" src="./images/note.svg" alt="qr" />
            <span>
              {" "}
              <Link style={{ color: "#1F1F1F" }} to="/contact2">
                Baker Home Contact
              </Link>
            </span>
          </div>
          <div className="nav-item">
            {" "}
            <img className="icon" src="./images/note.svg" alt="qr" />
            <span>
              {" "}
              <Link style={{ color: "#1F1F1F" }} to="/schedule">
                Baker Home Schedule
              </Link>
            </span>
          </div>
          <div className="nav-item">
            {" "}
            <img className="icon" src="./images/note.svg" alt="qr" />
            <span>
              {" "}
              <Link style={{ color: "#1F1F1F" }} to="/interest">
                Baker Home Interests
              </Link>
            </span>
          </div>
          <div
            className="nav-item"
            style={{ cursor: "pointer" }}
            onClick={logout}
          >
            {" "}
            <img className="icon" src="./images/arrow2.svg" alt="qr" />
            <span> Log out</span>
          </div>
        </div>
      </div>
    </div>
  );
}
