import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { http } from "../constant";
import { useNavigate } from "react-router";
import Bar from "../components/Bar";

export default function BakerContact() {
  const history = useNavigate();
  const [navState, setNavState] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const res = await axios.get(`https://bakerhomes.io/api/v1/hcontact`);

      if (res.data.code === 200) {
        console.log(res.data.data);
        setData(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const search = (evt) => {};

  const logout = () => {
    localStorage.removeItem("admin_name");
    localStorage.removeItem("att_token");
    history("/");
  };

  const checkUser = async () => {
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/user/profile`, {
      name: localStorage.getItem("admin_name"),
    });
    const role = res.data.data.role;
    if (role.toLowerCase() === "user") {
      localStorage.removeItem("admin_name");
      history("/");
    }
  };

  const changeNav = () => {
    setNavState(!navState);
  };

  useEffect(() => {
    checkUser();
    getData();
    if (!localStorage.getItem("admin_name")) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div>
      {" "}
      <main className="main">
        <Header search={search} logout={logout} changeNav={changeNav} />

        {navState === true ? <Bar /> : null}

        <section className="farm">
          <div className="req">Baker Homes Contact</div>
          {data.map((item) => {
            return (
              <div className="farm-item">
                <div className="item">Name - {item.name}</div>
                <div className="item">Email - {item.email}</div>
                <div className="item">Phone - {item.phone}</div>
                <div className="item">Message - {item.message}</div>
                <div className="item">
                  Date - {new Date(item.createdAt).toLocaleDateString()}
                </div>
              </div>
            );
          })}
        </section>
      </main>
    </div>
  );
}
