import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { http } from "../constant";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Bar from "../components/Bar";

export default function AdminDash() {
  const history = useNavigate();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [navState, setNavState] = useState(false);
  const [loader, setLoader] = useState(false);

  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const search = (evt) => {
    let val = evt.target.value;
    if (val.length > 0) {
      const ser = data1.filter(
        (item) =>
          item.user.first_name.toLowerCase().includes(val.toLowerCase()) ||
          item.user.last_name.toLowerCase().includes(val.toLowerCase())
      );
      setData(ser);
    } else {
      setData(data1);
    }
  };

  const logout = () => {
    localStorage.removeItem("admin_name");
    localStorage.removeItem("att_token");
    history("/");
  };

  const changeNav = () => {
    setNavState(!navState);
  };

  const checkUser = async () => {
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/user/profile`, {
      name: localStorage.getItem("admin_name"),
    });
    const role = res.data.data.role;
    if (role.toLowerCase() === "user") {
      localStorage.removeItem("admin_name");
      history("/");
    }
  };

  const reset = async () => {
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const password = passwordRef.current.value;
    const confirm = confirmPasswordRef.current.value;
    if (password !== confirm) {
      passwordRef.current.value = "";
      confirmPasswordRef.current.value = "";
      return NotificationManager.error(
        "Password and confirm password is not the same",
        "Error"
      );
    }
    setLoader(true);
    const data = {
      password,
      confirmPassword: confirm,
    };
    const res = await axios.patch(`${http}/api/v1/user/reset-password`, data);
    if (res.data.code === 200) {
      setLoader(false);
      NotificationManager.success("Password reset successfully", "Success");
      passwordRef.current.value = "";
      confirmPasswordRef.current.value = "";
    }
  };

  useEffect(() => {
    checkUser();

    if (!localStorage.getItem("admin_name")) {
      window.location.href = "/";
    }
  }, []);
  return (
    <div>
      <main className="main">
        <Header search={search} logout={logout} changeNav={changeNav} />

        {navState === true ? <Bar /> : null}

        <div className="setting_box">
          <div className="setting_text1">
            Use a password you can easily remember{" "}
          </div>
          <div className="setting_form_box">
            <div className="setting_label">New Password</div>
            <div>
              <input
                className="setting_input"
                placeholder="Enter your new password"
                ref={passwordRef}
                type="password"
              />
            </div>
          </div>
          <div className="setting_form_box">
            <div className="setting_label">Confirm New Password *</div>
            <div>
              <input
                type="password"
                className="setting_input"
                placeholder="Confirm your new password"
                ref={confirmPasswordRef}
              />
            </div>
          </div>
          {
            <div
              style={{ width: "20px" }}
              className={loader === true ? "loader" : "none"}
            ></div>
          }
          <button className="setting_but" onClick={reset}>
            Reset Password
          </button>
        </div>
      </main>
    </div>
  );
}
