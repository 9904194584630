import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Bar from "../components/Bar";
import axios from "axios";
import { http } from "../constant";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function EmployeeList() {
  const [navState, setNavState] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [user, setUser] = useState({});

  const firstRef = useRef();
  const lastRef = useRef();
  const roleRef = useRef();
  const emailRef = useRef();
  const positionRef = useRef();
  const staffRef = useRef();

  const firstRef1 = useRef();
  const lastRef1 = useRef();
  const roleRef1 = useRef();
  const emailRef1 = useRef();
  const positionRef1 = useRef();

  const submit = (email) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to delete this employee.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteEmployee(email),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const changeNav = () => {
    setNavState(!navState);
  };

  const getData = async () => {
    try {
      setLoader(true);
      let token = localStorage.getItem("att_token");
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      const res = await axios.get(`${http}/api/v1/attendance/users`);
      if (res.data.code === 200) {
        setLoader(false);
      }
      setData(res.data.data);
      setData1(res.data.data);
      return res.data;
    } catch (err) {
      console.log(err.response.data);
      return err;
    }
  };

  const search = (evt) => {
    let val = evt.target.value;
    if (val.length > 0) {
      const ser = data1.filter(
        (item) =>
          item.first_name.toLowerCase().includes(val.toLowerCase()) ||
          item.last_name.toLowerCase().includes(val.toLowerCase())
      );
      setData(ser);
    } else {
      setData(data1);
    }
  };

  const createEmployee = async (evt) => {
    evt.preventDefault();
    setLoader(true);
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    try {
      const cred = {
        first_name: firstRef.current.value,
        last_name: lastRef.current.value,
        role: roleRef.current.value,
        email: emailRef.current.value,
        password: "password",
        position: positionRef.current.value,
        staff_id: staffRef.current.value,
      };
      const res = await axios.post(`${http}/api/v1/user/signup`, cred);
      console.log(res.data.data);
      if (res.data.code === 201) {
        setLoader(false);
        setModal(false);
        window.location.href = "/list";
      }
      return res.data;
    } catch (err) {
      return err;
    }
  };

  const deleteEmployee = async (staff_id) => {
    setLoader(true);
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    try {
      const cred = {
        staff_id,
      };

      const res = await axios.post(`${http}/api/v1/user/delete`, cred);
      console.log(res.data.data);
      if (res.data.code === 200) {
        setLoader(false);
        setModal(false);
        window.location.href = "/list";
      }
      return res.data;
    } catch (err) {
      return err;
    }
  };

  const editEmployee = async (evt) => {
    evt.preventDefault();
    setLoader(true);
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    try {
      const cred = {
        first_name: firstRef1.current.value,
        last_name: lastRef1.current.value,
        role: roleRef1.current.value,
        email: emailRef1.current.value,
        position: positionRef1.current.value,
        staff_id: user.staff_id,
      };

      const res = await axios.patch(`${http}/api/v1/user`, cred);

      if (res.data.code === 200) {
        setLoader(false);
        setModal(false);
        window.location.href = "/list";
      }
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <main className="main">
        <div className="admin-inner">
          <div className="admin-f">
            <Link to="/admindash">
              <img className="logo" src="./images/logo.svg" alt="logo" />
            </Link>

            <input
              onChange={search}
              className="admin-header-input"
              placeholder="Search for employee name, ID"
            />

            <div className="admin-profile-mobile">
              <img
                style={{ paddingTop: "15px" }}
                src="./images/notifications.svg"
                alt="notifications"
              />
              <img src="./images/profile.svg" alt="profile" />
              <img
                onClick={changeNav}
                className="admin-arrow"
                src="./images/arrow.svg"
                alt="arrow"
                style={{
                  width: "12px",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          <div className="admin-section">
            <div className="admin-profile">
              <button className="clock-but-emp">
                <img src="./images/add.svg" alt="timer" />{" "}
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingBottom: "13px",
                  }}
                  onClick={() => setModal(true)}
                >
                  Add Employee
                </span>
              </button>
              <img
                style={{ paddingTop: "15px" }}
                src="./images/notifications.svg"
                alt="notifications"
              />
              <img src="./images/profile.svg" alt="profile" />
              <img
                onClick={changeNav}
                className="admin-arrow"
                src="./images/arrow.svg"
                alt="arrow"
                style={{
                  width: "12px",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
        {navState === true ? <Bar /> : null}

        {modal === true ? (
          <div className="modal">
            <div className="modal-content">
              <div className="close" style={{ cursor: "pointer" }}>
                <img
                  src="./images/Close.svg"
                  alt="close"
                  onClick={() => setModal(false)}
                />
              </div>
              <div className="inner-content">
                <div className="modal-label">Last Name</div>
                <input
                  className="modal-input"
                  ref={lastRef}
                  placeholder="Last Name"
                />
              </div>
              <div className="inner-content">
                <div className="modal-label">First Name</div>
                <input
                  className="modal-input"
                  ref={firstRef}
                  placeholder="First Name"
                />
              </div>
              <div className="inner-content">
                <div className="modal-label">Email Address</div>
                <input
                  className="modal-input"
                  ref={emailRef}
                  placeholder="Email Address"
                />
              </div>
              <div className="inner-content">
                <div className="modal-label">Role</div>
                <select ref={roleRef} className="modal-input">
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                  <option value="super-admin">Super Admin</option>
                </select>
              </div>
              <div className="inner-content">
                <div className="modal-label">Staff ID</div>
                <input
                  className="modal-input"
                  ref={staffRef}
                  placeholder="Staff ID"
                />
              </div>
              <div className="inner-content">
                <div className="modal-label">Position</div>
                <input
                  className="modal-input"
                  ref={positionRef}
                  placeholder="Position"
                />
              </div>
              {
                <div
                  style={{ width: "20px" }}
                  className={loader === true ? "loader" : "none"}
                ></div>
              }
              <div className="inner-content">
                <button onClick={createEmployee} className="modal-button">
                  Add Employee
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {edit === true ? (
          <div className="modal">
            <div className="modal-content">
              <div className="close" style={{ cursor: "pointer" }}>
                <img
                  src="./images/Close.svg"
                  alt="close"
                  onClick={() => setEdit(false)}
                />
              </div>
              <div className="inner-content">
                <div className="modal-label">Last Name</div>
                <input
                  className="modal-input"
                  ref={lastRef1}
                  placeholder="Last Name"
                  defaultValue={user.last_name}
                />
              </div>
              <div className="inner-content">
                <div className="modal-label">First Name</div>
                <input
                  className="modal-input"
                  ref={firstRef1}
                  placeholder="First Name"
                  defaultValue={user.first_name}
                />
              </div>
              <div className="inner-content">
                <div className="modal-label">Email Address</div>
                <input
                  className="modal-input"
                  ref={emailRef1}
                  placeholder="Email Address"
                  defaultValue={user.email}
                />
              </div>
              <div className="inner-content">
                <div className="modal-label">Role</div>
                <select
                  ref={roleRef1}
                  defaultValue={user.role}
                  className="modal-input"
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                  <option value="super-admin">Super Admin</option>
                </select>
              </div>
              <div className="inner-content">
                <div className="modal-label">Position</div>
                <input
                  className="modal-input"
                  ref={positionRef1}
                  placeholder="Position"
                  defaultValue={user.position}
                />
              </div>
              {
                <div
                  style={{ width: "20px" }}
                  className={loader === true ? "loader" : "none"}
                ></div>
              }
              <div className="inner-content">
                <button onClick={editEmployee} className="modal-button">
                  Edit Employee
                </button>
              </div>
            </div>
          </div>
        ) : null}

        <div className="sign-dash">
          <h3 className="act">Total Employees {data.length}</h3>

          <table>
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Employee Role</th>
            </tr>
            {data.map((item) => {
              return (
                <tr>
                  <td>{item.staff_id}</td>
                  <td>
                    {item.first_name} {item.last_name}
                  </td>
                  <td>{item.position}</td>
                  <td>
                    <img
                      src="./images/edit.svg"
                      style={{ paddingRight: "30px" }}
                      alt="edit"
                      onClick={() => {
                        setUser({
                          staff_id: item.staff_id,
                          first_name: item.first_name,
                          last_name: item.last_name,
                          position: item.position,
                          role: item.role,
                          email: item.email,
                        });
                        setEdit(true);
                      }}
                    />
                    <img
                      src="./images/delete.svg"
                      alt="edit"
                      onClick={() => submit(item.staff_id)}
                    />
                  </td>
                </tr>
              );
            })}
          </table>
          {
            <div
              style={{ width: "20px" }}
              className={loader === true ? "loader" : "none"}
            ></div>
          }
        </div>
      </main>
    </div>
  );
}
