import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { http } from "../constant";
import { useNavigate } from "react-router";
import Bar from "../components/Bar";

export default function BakerInterestDetail() {
  const history = useNavigate();
  const [navState, setNavState] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const result = [
    {
      date: "19 April 2023",
      chat: [
        {
          time: "8.00 am",
          name: "Oghosa Emmanuel",
          text: "I sent promo price list to Mr James and we plan to have a call later today",
        },

        {
          time: "9.00 am",
          name: "Yetunde Lawal",
          text: "Client visited stake city property and we have concluded payment plan . I also sent client land application form to him to fill. Expecting a feedback tomorrow",
        },
      ],
    },
    {
      date: "23 April 2023",
      chat: [
        {
          time: "8.00 am",
          name: "Oghosa Emmanuel",
          text: "I sent a follow-up email to the client this morning and no response yet",
        },

        {
          time: "9.00 am",
          name: "Kingsley",
          text: "I called the customer and escalated this to Bunmi immediately",
        },
      ],
    },
  ];

  const getData = async () => {
    try {
      const res = await axios.get(`https://bakerhomes.io/api/v1/farm`);

      if (res.data.code === 200) {
        console.log(res.data.data);
        setData(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const search = (evt) => {};

  const logout = () => {
    localStorage.removeItem("admin_name");
    localStorage.removeItem("att_token");
    history("/");
  };

  const checkUser = async () => {
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/user/profile`, {
      name: localStorage.getItem("admin_name"),
    });
    const role = res.data.data.role;
    if (role.toLowerCase() === "user") {
      localStorage.removeItem("admin_name");
      history("/");
    }
  };

  const changeNav = () => {
    setNavState(!navState);
  };

  useEffect(() => {
    checkUser();
    getData();
    if (!localStorage.getItem("admin_name")) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div>
      {" "}
      <main className="main">
        <Header search={search} logout={logout} changeNav={changeNav} />

        {navState === true ? <Bar /> : null}

        <section className="farm">
          <div className="detail">
            <div className="detail1">
              <div className="dtext1">Order Details</div>
              <div className="dbox">
                <div className="dtext2">Name </div>
                <div className="dtext3">Adesanya Joshua</div>
              </div>
              <div className="dbox">
                <div className="dtext2">Email Address</div>
                <div className="dtext3">adesanyajoshua@gmail.com</div>
              </div>

              <div className="dbox">
                <div className="dtext2">Phone Number</div>
                <div className="dtext3">08167486873</div>
              </div>

              <div className="dbox">
                <div className="dtext2">City</div>
                <div className="dtext3">Lagos</div>
              </div>

              <div className="dbox">
                <div className="dtext2">Preferred plot size/property</div>
                <div className="dtext3">600 SQM</div>
              </div>

              <div className="dbox">
                <div className="dtext2">Payment Plan</div>
                <div className="dtext3">Outright</div>
              </div>
            </div>
            <div className="detail2">
              <div className="detail2-inner">
                <div className="dtext4">Request #001</div>
                <div className="sflex">
                  <select className="select">
                    <option>In Progress</option>
                    <option>Resolved</option>
                  </select>
                  <select className="select">
                    <option>High</option>
                    <option>Low</option>
                    <option>Medium</option>
                  </select>
                </div>
              </div>

              {result.map((item) => {
                return (
                  <div>
                    <div className="outer">
                      <div className="date">{item.date}</div>

                      <div>
                        {item.chat.map((item) => {
                          return (
                            <div className="inner">
                              <div className="chat-flex">
                                <div className="ctext3">{item.name}</div>
                                <div className="ctext4">{item.time}</div>
                              </div>
                              <div>{item.text}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="butflex">
                <input className="cinput" placeholder="Type a comment" />
                <img src="./images/but.svg" alt="but" />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
