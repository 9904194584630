import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { http } from "../constant";
import { useNavigate } from "react-router";

export default function AdminLogin() {
  let emailRef = useRef();
  let passwordRef = useRef();
  const history = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("att_token");
    if (token) {
      history("/admindash");
    }
  }, []);

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const login = async (evt) => {
    evt.preventDefault();
    if (emailRef.current.value === "" || passwordRef.current.value === "") {
      return NotificationManager.error(
        "Please enter your Email or Password",
        "Error"
      );
    }
    try {
      setLoader(true);
      const cred = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      };

      const res = await axios.post(`${http}/api/v1/user/admin-login`, cred);
      console.log(res);
      if (res.data.code === 200) {
        setLoader(false);
        localStorage.setItem("admin_name", res.data.data.userData.first_name);
        localStorage.setItem("att_role", res.data.data.userData.role);
        localStorage.setItem("att_token", res.data.data.token);
        history("/admindash");
      }

      return res.data;
    } catch (err) {
      console.log(err);
      setLoader(false);
      console.log(err.response);
      if (err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError("An error occurred");
      }
      return err;
    }
  };

  return (
    <div>
      <main className="employee">
        <div className="employee-box1">
          <div>
            <img
              className="logo"
              style={{ paddingTop: "30px" }}
              src="./images/logo.svg"
              alt="logo"
            />
          </div>
          <div className="eml-header1">Track Employees Attendance</div>
          <div>
            <img
              src="./images/Illustration.svg"
              className="illustrate"
              alt="illustration"
            />
          </div>
        </div>

        <div className="employee-box21">
          <div className="login-inner">
            <form onSubmit={login}>
              <div className="email-div">
                <div className="empid">
                  Email Address <span style={{ color: "red" }}>*</span>
                </div>
                <div>
                  <input
                    className="emplinp"
                    placeholder="Enter your email"
                    ref={emailRef}
                  />
                </div>
              </div>

              <div className="password-div">
                <div className="empid">
                  Password <span style={{ color: "red" }}>*</span>
                </div>
                <div>
                  <input
                    className="emplinp"
                    type="password"
                    placeholder="Enter your password"
                    ref={passwordRef}
                  />
                </div>
              </div>
              <div className="forgot">
                <span style={{ color: "red", textAlign: "center" }}>
                  Forgot your password ?
                </span>
              </div>
              <div
                style={{
                  marginLeft: "150px",
                  marginRight: "auto",
                  width: "20px",
                }}
                className={loader === true ? "loader" : "none"}
              ></div>
              <div style={{ marginTop: "30px", color: "red" }}>
                {error.length > 0 ? error : ""}
              </div>
              <button onClick={login} className="login-button">
                Log In
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}
