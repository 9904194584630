import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { http } from "../constant";

export default function EmployeeDash() {
  const history = useNavigate();

  const [data, setData] = useState([]);
  const [greet, setGreet] = useState("");

  const getData = async () => {
    try {
      const res = await axios.post(`${http}/api/v1/attendance/staff`, {
        staff_id: localStorage.getItem("att-id"),
      });
      console.log(res.data.data);
      setData(res.data.data.slice(0, 4));
      return res.data;
    } catch (err) {
      console.log(err.response.data);
      return err;
    }
  };

  const clockOut = async () => {
    try {
      const cred = {
        id: localStorage.getItem("attid"),
      };

      const res = await axios.patch(
        `${http}/api/v1/attendance/clock-out`,
        cred
      );
      NotificationManager.info("Clocking Out", "Info");
      console.log("here", res.data);
      if (res.data.code === 200) {
        localStorage.removeItem("att-id");
        localStorage.removeItem("attid");
        window.location.href = "/employee";
      }
      return res.data;
    } catch (err) {
      console.log(err.response.data);
      console.log(err.response);
      return err;
    }
  };

  const greetings = () => {
    var d = new Date();
    var time = d.getHours();

    if (time < 12) {
      setGreet("Good Morning");
    }
    if (time >= 12) {
      setGreet("Good Afternoon");
    }
    if (time >= 16) {
      setGreet("Good Evening");
    }
  };

  useEffect(() => {
    const check = localStorage.getItem("att-id");
    if (!check) {
      history("/employee");
    }
    getData();
    greetings();
  }, []);

  const name = localStorage.getItem("att-name");
  return (
    <div>
      <main className="main">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            style={{ paddingTop: "30px" }}
            className="logo"
            src="./images/logo.svg"
            alt="logo"
          />

          <button
            style={{ cursor: "pointer" }}
            onClick={() => history("/leave")}
            className="lbut"
          >
            Leave
          </button>
        </div>

        <div className="section1">
          <div className="sign-head">
            {greet}, {name}
          </div>

          <button
            style={{ cursor: "pointer" }}
            onClick={clockOut}
            className="clock"
          >
            Clock-Out
          </button>
        </div>

        <div className="sign-dash">
          <h3 className="act">Attendance</h3>

          <table>
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Clock-In</th>
              <th>Clock-Out</th>
              <th>Date</th>
              <th>Location</th>
              <th>Status</th>
            </tr>
            {data.map((item) => {
              return (
                <tr>
                  <td>{item.staff_id}</td>
                  <td>
                    {item.user.first_name} {item.user.last_name}
                  </td>
                  <td>{new Date(item.date).toLocaleTimeString()}</td>
                  <td>
                    {item["clock_out"] === null
                      ? "Not clocked out"
                      : new Date(item["clock_out"]).toLocaleTimeString()}
                  </td>
                  <td>{new Date(item.date).toDateString()}</td>
                  <td>{item.location}</td>
                  <td>
                    {item.attended === "true" ? (
                      <span style={{ color: "green" }}>Approved</span>
                    ) : item.attended === "false" ? (
                      <span style={{ color: "red" }}>Rejected</span>
                    ) : (
                      <span>Pending</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </main>
    </div>
  );
}
