import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { http } from "../constant";
import { useNavigate } from "react-router";
import Bar from "../components/Bar";
import Modal from "../components/Modal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function LeaveApproval() {
  const history = useNavigate();
  const [data, setData] = useState([]);
  const [inData, setInData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [outData, setOutData] = useState([]);
  const [greet, setGreet] = useState("");
  const [navState, setNavState] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const name = localStorage.getItem("admin_name");

  const getData = async () => {
    try {
      setLoader(true);
      let token = localStorage.getItem("att_token");
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      const res = await axios.get(`${http}/api/v1/attendance/all`);
      if (res.data.code === 200) {
        setLoader(false);
      }
      let newData = res.data.data.attendance.reverse();
      let allUsers = res.data.data.allUsers.length;
      let inp = newData.filter(
        (item) => item.attended === "true" && item.clock_out === null
      );

      let out = Number(allUsers) - Number(inp.length);
      setInData(inp);
      setOutData(out);
      setData(res.data.data.attendance.reverse());
      setData1(res.data.data.attendance.reverse());
      return res.data;
    } catch (err) {
      console.log(err.response.data);
      setLoader(false);
      return err;
    }
  };

  const submitA = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to approve this leave.",
      buttons: [
        {
          label: "Yes",
          onClick: () => approveLeave(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const submitB = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to reject this leave.",
      buttons: [
        {
          label: "Yes",
          onClick: () => rejectLeave(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const approveLeave = async (id) => {
    try {
      const cred = {
        id,
      };

      const res = await axios.post(`${http}/api/v1/leave/approve`, cred);
      if (res.data.code === 200) {
        NotificationManager.success(
          "Leave request has been approved",
          "Success"
        );
      }

      setTimeout(() => {
        window.location.reload();
      }, 10000);
    } catch (err) {
      NotificationManager.error("An errorr occured", "Error");
    }
  };

  const rejectLeave = async (id) => {
    try {
      const cred = {
        id,
      };

      const res = await axios.post(`${http}/api/v1/leave/reject`, cred);
      if (res.data.code === 200) {
        NotificationManager.success(
          "Leave request has been rejected",
          "Success"
        );
      }

      setTimeout(() => {
        window.location.reload();
      }, 10000);
    } catch (err) {
      NotificationManager.error("An errorr occured", "Error");
    }
  };

  const getLeave = async () => {
    try {
      const res = await axios.get(`${http}/api/v1/leave/pending`);
      console.log(res.data.data);
      setData2(res.data.data);
    } catch (err) {
      NotificationManager.error("An errorr occured", "Error");
    }
  };

  const checkUser = async () => {
    let token = localStorage.getItem("att_token");
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/user/profile`, {
      name: localStorage.getItem("admin_name"),
    });
    const role = res.data.data.role;
    if (role.toLowerCase() === "user") {
      localStorage.removeItem("admin_name");
      history("/");
    }
  };

  const search = (evt) => {
    let val = evt.target.value;
    if (val.length > 0) {
      const ser = data1.filter(
        (item) =>
          item.user.first_name.toLowerCase().includes(val.toLowerCase()) ||
          item.user.last_name.toLowerCase().includes(val.toLowerCase())
      );
      setData(ser);
    } else {
      setData(data1);
    }
  };

  const greetings = () => {
    var d = new Date();
    var time = d.getHours();

    if (time < 12) {
      setGreet("Good Morning");
    }
    if (time >= 12) {
      setGreet("Good Afternoon");
    }
    if (time >= 16) {
      setGreet("Good Evening");
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const changeNav = () => {
    setNavState(!navState);
  };

  useEffect(() => {
    checkUser();
    getData();
    greetings();
    getLeave();
    if (!localStorage.getItem("admin_name")) {
      window.location.href = "/";
    }
  }, []);
  return (
    <div>
      <main className="main">
        <Header search={search} changeNav={changeNav} />
        {navState === true ? <Bar /> : null}

        <div className="section1">
          <div className="sign-head">
            {greet}, {name}
          </div>
        </div>

        <div className="sign-dash">
          <h3 className="act">Leave Approval</h3>

          <table>
            <tr>
              <th>Employee ID</th>
              <th>Leave Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            {data2.map((item) => {
              return (
                <tr>
                  <td>{item.staff_id}</td>
                  <td>{item.leave_type}</td>
                  <td>{new Date(item.start_date).toDateString()}</td>
                  <td>{new Date(item.end_date).toDateString()}</td>
                  <td>
                    {item.status === "true" ? (
                      <span style={{ color: "green" }}>Approved</span>
                    ) : item.attended === "false" ? (
                      <span style={{ color: "red" }}>Rejected</span>
                    ) : (
                      <span>Pending</span>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        openModal();
                        setModalData(item);
                      }}
                      style={{ cursor: "pointer" }}
                      className="butt"
                    >
                      View More
                    </button>
                  </td>
                  <td>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={() => submitA(item.id)}
                      className="butt"
                    >
                      Approve
                    </button>
                  </td>
                  <td>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={() => submitB(item.id)}
                      className="butt"
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              );
            })}
          </table>
          {
            <div
              style={{ width: "20px" }}
              className={loader === true ? "loader" : "none"}
            ></div>
          }
        </div>
        <Modal isOpen={modalOpen} onClose={closeModal}>
          <div>
            <div className="modalBox">
              <div className="modalinner">
                Employee Id - {modalData?.staff_id}
              </div>

              {modalData?.user ? (
                <div className="modalinner">
                  Employee Name - {modalData?.user?.first_name}{" "}
                  {modalData.user.last_name}
                </div>
              ) : null}

              <div className="modalinner">
                Leave Type - {modalData?.leave_type}
              </div>
              <div className="modalinner">
                Start Date - {new Date(modalData?.start_date).toDateString()}
              </div>
              <div className="modalinner">
                End Date - {new Date(modalData?.end_date).toDateString()}
              </div>
              <div className="modalinner">Reason - {modalData?.reason}</div>
            </div>
          </div>
        </Modal>
      </main>
    </div>
  );
}
